import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { TILE_STATES } from '../../../components/cap/constants';
import {
    generateSKU,
    getProjectsFromCookie,
    isInventoryCheckRequired
} from '../../../components/global/utils/commonUtils';
import { isValidString, logError } from '../../../components/global/utils/logger';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { ERROR_MSG } from '../../../constants/errorMappingCodes';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { useCheckUser } from '../../../hooks/useCheckUser';
import { useAuthorityToken } from '../../utils/hooks';
import parseError from '../../utils/parseError';
import {
    RESET_GEOGRAPHY,
    RESET_ORDER_ESTIMATES,
    SET_CART_AVAILABLE_UNVAILABLE_ITEMS,
    SET_CART_ITEMS,
    SET_IS_CART_LOADING
} from '../constants';
import { generateLocalCartItems, updateLocalCartItems } from './utils';

/**
 * Adds an item to the cart. If the cart doesn't exist then it's created
 *
 * @param {Object} payload a parameters object with the following structure:
 *      cartDetailsQuery - the query object to execute
 *      createCartMutation - the mutation to execute to create the cart
 *      addToCartMutation - the mutation to execute to add the item to the cart
 *      dispatch - the dispatch callback for the cart context
 *      cartItems - the items to add to the cart
 *      cartId - the id of the cart in which to add the items. This could be `undefined` or `null`
 */

 export const addItemToCart = async payload => {
    const { createCartMutation, addToCartMutation, physicalCartItems, virtualCartItems, bundleCartItems, dispatch } =
        payload;

    try {
        const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');
        let cartId = payload?.cartId;
        if (!cartId) {
            let { data: newCartData } = await createCartMutation();
            cartId = newCartData?.createEmptyCart;
        }
        let variables = { cartId, cartItems: physicalCartItems };
        if (bundleCartItems?.length > 0) {
            variables = { cartId, cartItems: bundleCartItems };
        } else if (physicalCartItems?.length > 0 && virtualCartItems?.length > 0) {
            variables = { cartId, virtualCartItems, simpleCartItems: physicalCartItems };
        } else if (virtualCartItems?.length > 0) {
            variables = { cartId, cartItems: virtualCartItems };
        }
        const res = await addToCartMutation({ variables });
        dispatch({ type: 'cartId', cartId });
        const atcData = {
            cartId,
            created_at: res?.data?.addSimpleProductsToCart?.cart?.created_at,
            updated_at: formattedDate,
            total_quantity: res?.data?.addSimpleProductsToCart?.cart?.total_quantity || 0
        };
        dispatch({
            type: SET_CART_ITEMS,
            id: cartId,
            created_at: atcData?.created_at,
            updated_at: atcData?.updated_at,
            total_quantity: atcData?.total_quantity
        });
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY, atcData?.total_quantity);
        updateLocalCartItems({
            id: variables?.cartItems?.[0]?.data?.sku,
            quantity: variables?.cartItems?.[0]?.data?.quantity
        });
        return { atcData, error: '' };
    } catch (error) {
        if (error?.response?.status >= 500) {
            logError(error, true, 'addItemToCart', [payload]);
        } else {
            logError(error, false, 'addItemToCart', [payload]);
        }
        return { atcData: {}, error };
    }
};

/**
 * Retrieves the cart details
 * @param {Object} payload a parameters object with the following structure:
 *      cartDetailsQuery - the query object to execute
 *      dispatch - the dispatch callback for the cart context
 *      cartId - the id of the cart
 */

const getRequiredPCsForInventory = (startDateAndTime, nearbyPcs) => {
    const NewAddress = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)
        ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS))
        : false;
    const pcList = nearbyPcs
        ? JSON.stringify(nearbyPcs)
        : sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST);
    const projectInfoCookies = getProjectsFromCookie();
    const userType = useCheckUser();
    const { disableinventorycheck, rollbackToCi } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};
    const isInventoryCheckDisabled = disableinventorycheck || false;
    const isAtpDisabled = rollbackToCi || false;
    if (isValidString(isInventoryCheckDisabled)) {
        return '';
    } else if (!NewAddress && !isValidString(projectInfoCookies?.SLA) && userType == USER_TYPE.CREDIT) {
        return '';
    } else if (isInventoryCheckRequired() && pcList) {
        if (!isValidString(isAtpDisabled)) {
            if (!isValidString(startDateAndTime)) {
                return JSON.parse(pcList?.replaceAll('01_', '')?.replaceAll('02_', ''));
            }
            return '';
        }
        return JSON.parse(pcList?.replaceAll('01_', '')?.replaceAll('02_', ''));
    } else {
        return '';
    }
};
export const getCartDetails = async payload => {
    const {
        cartDetailsQuery,
        dispatch,
        cartId,
        filterDispatch,
        handleATPCart,
        isRentalDetailsAvailable = true,
        ...rest
    } = payload;
    const {
        pc,
        dlSt,
        dlNumber,
        distance,
        timeType,
        equipment,
        salesEquipments,
        jobSiteAddr1,
        jobSiteAddr2,
        lat,
        long,
        jobSiteCity,
        jobSiteState,
        jobSiteZip,
        startDateAndTime,
        endDateAndTime,
        lastResort,
        fulfillment,
        pcLat,
        pcLong,
        projectId,
        account,
        isDeliveryEstimate,
        signal
    } = rest;
    try {
        const userType = useCheckUser();
        const projectInfoCookies = getProjectsFromCookie();
        const isCreditNewAddressFromStorage = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)
            ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS))
            : false;
        if (!cartId) {
            return;
        }
        const context = {
            headers: {
                    'company-id': parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
                    'required-pcs': ''
                }
        };
        if (signal) {
            context.fetchOptions =  { signal };
        }

        const cartInputDataObj = JSON.stringify({
            pc,
            jobSiteAddr1,
            jobSiteAddr2,
            jobSiteCity,
            jobSiteState,
            jobSiteZip,
            dlSt,
            dlNumber,
            startDateAndTime,
            endDateAndTime,
            lat,
            long,
            distance,
            timeType,
            equipment,
            salesEquipments,
            lastResort,
            fulfillment,
            pcLat,
            pcLong,
            projectId,
            account,
            isDeliveryEstimate,
        });
        const { data, errors } = await cartDetailsQuery({
            variables: {
                cartId,
                cartInputData: cartInputDataObj !== "{}"
                                ? cartInputDataObj
                                : undefined,
            },
            fetchPolicy: 'network-only',
            context
        });
        //TODO- make wires crossed as true
        if (errors) {
            logError(errors[0]?.message || ERROR_MSG?.INTERNAL_SERVER_ERROR, false, 'getCartDetails', [payload]);
        }
        let estimatesData = cloneDeep(data.cart);
        const estimatesResponse = estimatesData?.estimatesResponse?.status?.code;
        const chargedItems = { ...estimatesData?.estimatesResponse?.estimate?.itemizedCharges?.products };
        let availableCartItems = [];
        let unavailableCartItems = [];
        let estimateCartItems = [];

        if (!isRentalDetailsAvailable || (parseInt(estimatesResponse) >= 400 && parseInt(estimatesResponse) < 500)) {
            const cartItems = estimatesData?.items;
            for (let i in cartItems) {
                estimateCartItems.push({
                    ...cartItems[i],
                    product: {
                        ...cartItems[i].product,
                        pc_availability: true
                    }
                });
                availableCartItems.push({
                    ...cartItems[i],
                    product: {
                        ...cartItems[i].product,
                        pc_availability: true
                    }
                });
            }
        } else {
            iterateCartItems: for (let i in estimatesData?.items) {
                for (let j in chargedItems) {
                    if (
                        generateSKU(chargedItems[j]?.catId, chargedItems[j]?.classId) ===
                        estimatesData?.items[i]?.product?.sku
                    ) {
                        estimateCartItems.push({
                            ...estimatesData.items[i],
                            product: {
                                ...estimatesData.items[i].product,
                                pc_availability: true
                            }
                        });
                        availableCartItems.push({
                            ...estimatesData.items[i],
                            product: {
                                ...estimatesData.items[i].product,
                                pc_availability: true
                            }
                        });
                        continue iterateCartItems;
                    }
                }
                estimateCartItems.push({
                    ...estimatesData.items[i],
                    product: {
                        ...estimatesData.items[i].product,
                        pc_availability: false
                    }
                });
                unavailableCartItems.push({
                    ...estimatesData.items[i],
                    product: {
                        ...estimatesData.items[i].product,
                        pc_availability: false
                    }
                });
            }
        }

        estimatesData.items = estimateCartItems;
        const isInstorePickupFromStorage = JSON.parse(
            localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP) || false
        );

        /**
         * Response types on the basis of timeType
         *
         * timeType:pickup => cart.estimatesResponse.estimate => pickup
         * timeType:delivery => cart.estimatesResponse.estimate => delivery
         * timeType:delivery-pickup => cart.estimatesResponse.estimate => delivery
         * timeType:delivery-pickup => cart.estimatesResponse.estimatePickup => pickup
         */

        if (estimatesData?.estimatesResponse?.estimate) {
            const estimatesDefaultTotals = modifyEstimates(estimatesData.estimatesResponse.estimate);

            const estimatesDefault = {
                ...data.cart.estimatesResponse.estimate,
                totals: {
                    ...data.cart.estimatesResponse.estimate.totals,
                    fuelCharges: estimatesDefaultTotals?.fuelCharges,
                    deliveryPickUpCharges: estimatesDefaultTotals?.deliveryPickUpCharges,
                    allOtherCharges: estimatesDefaultTotals?.allOtherCharges || 0
                }
            };

            if (estimatesData?.estimatesResponse?.estimatePickup) {
                // if timeType is delivery-pickup
                // in case of delivery-pickup we are keeping both estimates in session storage
                const estimatesPickupTotals = modifyEstimates(estimatesData.estimatesResponse.estimatePickup);

                const estimatesPickup = {
                    ...data.cart.estimatesResponse.estimatePickup,
                    totals: {
                        ...data.cart.estimatesResponse.estimatePickup.totals,
                        fuelCharges: estimatesPickupTotals?.fuelCharges,
                        deliveryPickUpCharges: estimatesPickupTotals?.deliveryPickUpCharges,
                        allOtherCharges: estimatesPickupTotals?.allOtherCharges || 0
                    }
                };

                const estimates = isInstorePickupFromStorage ? estimatesPickup : estimatesDefault;

                estimatesData = {
                    ...data.cart,
                    estimatesResponse: {
                        estimate: estimates
                    },
                    items: estimateCartItems
                };

                sessionStorage.setItem(
                    STORAGE_CONFIG.SESSION_STORAGE.ESTIMATES_PICKUP,
                    JSON.stringify(estimatesPickup)
                );
                sessionStorage.setItem(
                    STORAGE_CONFIG.SESSION_STORAGE.ESTIMATES_DELIVERY,
                    JSON.stringify(estimatesDefault)
                );
            } else {
                // if timeType is delivery or pickup
                estimatesData = {
                    ...data.cart,
                    estimatesResponse: {
                        estimate: estimatesDefault
                    },
                    items: estimateCartItems
                };
            }
        } else if (data?.cart?.estimatesResponse?.status?.code >= parseInt(500)) {
            logError(data?.cart?.estimatesResponse?.status?.desc, true, 'getCartDetails', [payload]);
        }
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY, estimatesData?.total_quantity || 0);
        generateLocalCartItems(availableCartItems);
        estimatesData = { ...estimatesData, availableCartItems, unavailableCartItems };
        if (userType === USER_TYPE.CREDIT && !isCreditNewAddressFromStorage) {
            if (!projectInfoCookies?.SLA) {
                estimatesData = {
                    ...estimatesData,
                    prices: {},
                    estimatesResponse: {}
                };
                filterDispatch({
                    type: RESET_GEOGRAPHY
                });
                dispatch({
                    type: RESET_ORDER_ESTIMATES
                });
            }
        }
        dispatch({ type: 'cart', cart: estimatesData });
        return estimatesData;
    } catch (error) {
        logError(error, false, 'getCartDetails', [payload]);
        dispatch({ type: 'error', error: error.toString() });
        return {};
    }
};
const modifyEstimates = estimates => {
    if (estimates) {
        const deliveryObj = estimates?.miscCharges?.find(item => item.type.indexOf('DELIVERY') > -1);
        const pickupObj = estimates?.miscCharges?.find(item => item.type.indexOf('PICKUP') > -1);
        const fuelObj = estimates?.miscCharges?.find(item => item.type.indexOf('FUEL') > -1);

        const fuelCharges = fuelObj?.charge ? fuelObj?.charge : 0;
        const deliveryCharge = deliveryObj?.charge ? deliveryObj?.charge : 0;
        const pickupCharge = pickupObj?.charge ? pickupObj?.charge : 0;
        const deliveryPickUpCharges = deliveryCharge + pickupCharge;
        const allOtherCharges = estimates?.totals?.miscCharges - deliveryPickUpCharges;

        return { fuelCharges, deliveryPickUpCharges, allOtherCharges };
    }
};

/**
 * Removes an item from the cart
 *
 * @param {Object} payload a parameters object with the following structure:
 *      cartDetailsQuery - the query object to execute to retrieve the details
 *      removeItemMutation - the mutation to execute to remove the items
 *      dispatch - the dispatch callback for the cart context
 *      cartId - the id of the cart
 *      itemId - the id of the item to remove
 */
export const removeItemFromCart = async payload => {
    const { cartDetailsQuery, removeItemMutation, cartId, itemUid, dispatch } = payload;

    try {
        const res = await removeItemMutation({
            variables: { cartId, itemUid }
        });

        const items = res?.data?.removeItemFromCart?.cart?.items || [];
        dispatch({
            type: SET_CART_ITEMS,
            id: cartId,
            items: items,
            total_quantity: res?.data?.removeItemFromCart?.cart?.total_quantity
        });
        localStorage.setItem(
            STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY,
            res?.data?.removeItemFromCart?.cart?.total_quantity || 0
        );
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CART_UPDATED_WITH_PRICE, false);
        generateLocalCartItems(items);
        return items;
    } catch (error) {
        if (error?.response?.status >= 500) {
            logError(error, true, 'removeItemFromCart', [payload]);
        } else {
            logError(error, false, 'removeItemFromCart', [payload]);
        }
        dispatch({ type: 'error', error: error?.toString() });
    }
};

/**
 * Merges two shopping carts
 *
 * @param {Object} payload a parameters object with the following structure:
 *      cartDetailsQuery - the query object to execute
 *      mergeCartsMutation - the mutation to execute to merge the carts
 *      dispatch - the dispatch callback for the cart context
 *      cartId - the id of the cart to be merged
 *      customerCartId - the id of the cart that the other cart will the merged into (i.e. the customer cart)
 */
export const mergeCarts = async payload => {
    const { cartDetailsQuery, mergeCartsMutation, cartId, customerCartId, dispatch, filterDispatch } = payload;
    try {
        const { data } = await mergeCartsMutation({
            variables: {
                sourceCartId: cartId,
                destinationCartId: customerCartId
            }
        });
        await getCartDetails({ cartDetailsQuery, dispatch, cartId: data.mergeCarts.id, filterDispatch });
        return data.mergeCarts.id;
    } catch (error) {
        if (error?.response?.status >= 500) {
            logError(error, true, 'mergeCarts', [payload]);
        } else {
            logError(error, false, 'mergeCarts', [payload]);
        }
        dispatch({ type: 'error', error: parseError(error) });
    }
};

export const updateCartItem = async payload => {
    const {
        cartDetailsQuery,
        updateCartItemMutation,
        cartId,
        cartItemUid,
        itemQuantity,
        pc,
        customerNumber,
        jobSite,
        handleATPCart,
        dispatch,
        cartItems
    } = payload;
    try {
        const res = await updateCartItemMutation({
            variables: { cartId, cartItems }
        });

        const items = res?.data?.updateCartItems?.cart?.items || [];
        dispatch({
            type: SET_CART_ITEMS,
            id: cartId,
            items: items,
            total_quantity: res?.data?.updateCartItems?.cart?.total_quantity
        });
        /* To update the cart contex when unavailable items is removed form the cart */
        let availableCartItems = [];
        let unavailableCartItems = [];
        if (items?.length > 0) {
            items?.forEach(data => {
                let tileStatus = handleATPCart({
                    catsku: data?.product?.sku,
                    inventoryDataObj: data?.product?.ec_pc_inventory
                });
                if (tileStatus === TILE_STATES.AVAILABLE || tileStatus === TILE_STATES.AVAILABLE_WITH_WARNING) {
                    availableCartItems.push(data);
                } else {
                    unavailableCartItems.push(data);
                }
            });
        }
        dispatch({ type: SET_CART_AVAILABLE_UNVAILABLE_ITEMS, availableCartItems, unavailableCartItems });
        localStorage.setItem(
            STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY,
            res?.data?.updateCartItems?.cart?.total_quantity || 0
        );
        generateLocalCartItems(items);
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CART_UPDATED_WITH_PRICE, false);
        if (!res?.data?.updateCartItems?.cart?.total_quantity) {
            dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
        }
        return items;
    } catch (error) {
        if (error?.response?.status >= 500) {
            logError(error, true, 'updateCartItem', [payload]);
        } else {
            logError(error, false, 'updateCartItem', [payload]);
        }
        dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
        dispatch({ type: 'error', error: parseError(error) });
    }
};

export const createReservationProcess = async props => {
    const { createReservationMutation, payload, dispatch } = props;
    try {
        let { data, error } = await createReservationMutation({ variables: payload });
        if (error) {
            logError(error, false, 'createReservationProcess', [props]);
        }
        dispatch({ type: 'reservation', data: data });
        return { data, error };
    } catch (error) {
        dispatch({ type: 'error', error: error.toString() });
        if (error?.response?.status >= 500) {
            logError(error, true, 'createReservationProcess', [props]);
        } else {
            logError(error, false, 'createReservationProcess', [props]);
        }
    }
};


export const createOrTransmitQuotePayloadProcess = async props => {
    const { createOrTransmitQuoteMutation, payload, dispatch } = props;
    try {
        let { data, error } = await createOrTransmitQuoteMutation({ variables: payload });
        return { data: data?.createQuote, error };
    } catch (error) {
        logError(error, false, 'createOrTransmitQuotePayloadProcess', [props]);
        return { data: null, error };
    }
};

export const editOrSaveQuotePayloadProcess = async props => {
    const { editOrSaveQuoteMutation, payload, dispatch } = props;
    try {
        let { data, error } = await editOrSaveQuoteMutation({ variables: payload });
        return { data: data?.editQuote, error };
    } catch (error) {
        logError(error, false, 'editOrSaveQuotePayloadProcess', [props]);
        return { data: null, error };
    }
};

export const validateRefreshTokenMutation = async props => {
    const { createValidateTokenByRefreshMutation, dispatch } = props;
    const [refreshToken] = useAuthorityToken('refreshtoken');
    let payload = { refreshToken: refreshToken };
    if (isValidString(refreshToken)) {
        try {
            let { data, error } = await createValidateTokenByRefreshMutation({ variables: payload });
            return { data: data?.validateTokenByRefresh, error };
        } catch (error) {
            logError(error, false, 'validateRefreshToken', [props]);
            dispatch({ type: 'error', error: error.toString() });
            return { data: null, error };
        }
    } else {
        logError('refresh token not present', false, 'validateRefreshToken', [props]);
        return { data: null, error: 'refresh token not present' };
    }
};
