/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2021 Adobe
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
import { gql } from '@apollo/client';

const MUTATION_GENERATE_TOKEN_MAGENTO = gql`
    mutation generateCustomerTokenUsingOkta(
        $discardCustomerCart: Boolean
        $guestCartId: String
        $accessToken: String!
        $idToken: String
        $refreshToken: String!
    ) {
        generateCustomerTokenUsingOkta(
            discardCustomerCart: $discardCustomerCart
            guestCartId: $guestCartId
            accessToken: $accessToken
            idToken: $idToken
            refreshToken: $refreshToken
        ) {
            message
            customer_token
            cart_token
            oktaUserId
            swsguid
            magento_session_id
            firstname
            lastname
            email
            type
            driver_license_state
            driver_license_number
            logintoken
            line1
            line2
            city
            state
            postal_code
            address_latitude
            address_longitude
            lastreservationaddressline1
            lastreservationaddresscity
            lastreservationaddressstate
            lastreservationaddresspostal_code
            lastreservationaddresscountry
            telephone
            companyId
            fax
            job_title
            accounts {
                id
                name
                companyId
                chargeRPP
                corplink
                isCorpLinkAccount
                accountStatus
                freeDelivery
                freePickUp
                permission {
                    reservation
                }
                address {
                    address1
                    address2
                    city
                    state
                    zip
                }
            }
            skip_qty {
                sku
                qty
                partial_update
                added_qty
            }
            coachmark {
                pickstore
                location
                producttile
            }
        }
    }
`;
export default MUTATION_GENERATE_TOKEN_MAGENTO;
