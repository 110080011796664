import moment from 'moment';
import { useCartState } from '../../contexts/cart';
import { useFilterState } from '../../components/cap';
import { useCheckDeliveryCharge } from '../useCheckDeliveryCharge';
import { useCheckAuthorityType, useCheckUser } from '../useCheckUser';
import { usePageType } from '../usePageType';
import useCartEstimate from '../../components/App/hooks/useCartEstimate';
import { useAtp } from '../../components/cap/hooks/useATP';
import { isCheckoutPage, checkIsRequestQuote, getUniqueCategories } from '../../components/global/utils/commonUtils';
import { isValidString } from '../../components/global/utils/logger';
import { getDateDiffInHrs } from '../../components/cap/utils/atputils';
import { TILE_STATES } from '../../components/cap/constants';
import { VARIABLE_CONFIG } from '../../constants/analyticsConstants/Variables';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
import { USER_TYPE } from '../../constants/userDetailsConstants';
import { AUTHORITY_TYPE } from '../../components/global/constants';
import { DELIVERY_CHARGE_FLAG } from '../../constants/cartConstants';

const useAnalyticsHelper = () => {
    const userType = useCheckUser();
    const [{ viewCart, projectDetails, startDate }] = useFilterState();
    const [{ cart, homeAssetsRates }] = useCartState();
    const [{ isEstimatesWithOwnedPc }] = useCartEstimate();
    const deliveryChargeFlag = useCheckDeliveryCharge();
    const authorityType = useCheckAuthorityType();
    const pageType = usePageType();
    const { handleATP } = useAtp();
    const {
        CHANGE_DATES_AND_STORE_GRT72HRS,
        CHANGE_DATES_AND_STORE_GRT24HRS,
        CHANGE_DATES_OR_STORE_GRT72HRS,
        CHANGE_DATES_OR_STORE_GRT24HRS,
        EMPTY_LOCATION_AND_EMPTY_DATES,
        EMPTY_LOCATION,
        EMPTY_DATES,
        CHANGE_DATES_GRT24HRS,
        CHANGE_DATES_GRT72HRS,
        CHANGE_STORE,
        UNAVAILABLE,
        AVAILABLE,
        AVAILABLE_WITH_WARNING
    } = TILE_STATES || {};

    const getItemAvailabilityTileStatus = tileStatus => {
        switch (tileStatus) {
            case CHANGE_DATES_AND_STORE_GRT72HRS:
            case CHANGE_DATES_AND_STORE_GRT24HRS:
                return VARIABLE_CONFIG.TILE_STATUS.CHANGE_STORE_AND_DATE;

            case CHANGE_DATES_OR_STORE_GRT72HRS:
            case CHANGE_DATES_OR_STORE_GRT24HRS:
                return VARIABLE_CONFIG.TILE_STATUS.CHANGE_STORE_OR_DATE;

            case EMPTY_LOCATION_AND_EMPTY_DATES:
                return VARIABLE_CONFIG.TILE_STATUS.NO_LOCATION_AND_DATES;

            case EMPTY_LOCATION:
                return VARIABLE_CONFIG.TILE_STATUS.NO_LOCATION;

            case EMPTY_DATES:
                return VARIABLE_CONFIG.TILE_STATUS.NO_DATES;

            case CHANGE_DATES_GRT24HRS:
            case CHANGE_DATES_GRT72HRS:
                return VARIABLE_CONFIG.TILE_STATUS.CHANGE_DATE;

            case UNAVAILABLE:
                return VARIABLE_CONFIG.TILE_STATUS.UNAVAILABLE;

            case AVAILABLE:
            case AVAILABLE_WITH_WARNING:
                return VARIABLE_CONFIG.TILE_STATUS.RENT;

            case CHANGE_STORE:
                return VARIABLE_CONFIG.TILE_STATUS.CHANGE_STORE;

            default:
                return VARIABLE_CONFIG.TILE_STATUS.DEFAULT;
        }
    };

    const getAvailabilityStatus = (sku, inventory, disableAtcOption, showonlinecatalog, pcAvailability) => {
        const tileStatus = handleATP({ catsku: sku, inventoryDataObj: inventory, pcAvailability });
        if (checkIsRequestQuote(showonlinecatalog, disableAtcOption)?.isRequestQuote) {
            return VARIABLE_CONFIG.TILE_STATUS.REQUEST_A_QUOTE;
        }
        return getItemAvailabilityTileStatus(tileStatus);
    };

    const getDeliveryFee = () => {
        let delivery_fee = 0;
        /* handling for minicart, if user clicks on get estimates pass delivery_fee if present */
        if (viewCart?.showDeliveryEstimate) {
            if (deliveryChargeFlag === DELIVERY_CHARGE_FLAG.TBD) {
                delivery_fee = DELIVERY_CHARGE_FLAG.TBD;
            } else {
                delivery_fee =
                    cart?.estimatesResponse?.estimate?.totals?.deliveryPickUpCharges ||
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            }
        }
        /* handling for checkout, if user opted round-trip delivery pass delivery_fee if present */
        if (isCheckoutPage()) {
            if (isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP))) {
                delivery_fee = 0;
            } else {
                if (deliveryChargeFlag === DELIVERY_CHARGE_FLAG.TBD) {
                    delivery_fee = DELIVERY_CHARGE_FLAG.TBD;
                } else {
                    delivery_fee =
                        cart?.estimatesResponse?.estimate?.totals?.deliveryPickUpCharges ||
                        VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
                }
            }
        }
        return delivery_fee;
    };

    const getStartingInStatus = () => {
        const dateDiff = getDateDiffInHrs(startDate, moment().format('YYYY-MM-DDTHH:mm:ss'));
        if (startDate) {
            if (dateDiff >= 72) {
                return VARIABLE_CONFIG.STARTING_IN_STATUS.GRT72HRS;
            } else if (dateDiff >= 24 && dateDiff < 72) {
                return VARIABLE_CONFIG.STARTING_IN_STATUS.GRT24HRS;
            } else if (dateDiff >= 0 && dateDiff < 24) {
                return VARIABLE_CONFIG.STARTING_IN_STATUS.LESS24HRS;
            }
        }
        return VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
    };

    const bussinessSize = () => {
        if (authorityType === AUTHORITY_TYPE.P2P) {
            return VARIABLE_CONFIG.BUSINESS_SIZE.MIDDLE;
        } else if (userType === USER_TYPE.CREDIT) {
            return VARIABLE_CONFIG.BUSINESS_SIZE.MIDDLE;
        } else if (userType === USER_TYPE.CASH) {
            return VARIABLE_CONFIG.BUSINESS_SIZE.SMALL;
        }
    };

    const getItemStatusDetails = items => {
        const unavailable_items = items?.some?.(item => item?.tileStatus !== AVAILABLE) || false;
        return { unavailable_items };
    };

    const getAddressPayload = (cookieAccount = {}, isAddressFromCookie = false, localLocation = {}) => {
        const NewAddress = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)) || false;
        const viewCartData = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART)) || {};
        let line_1, line_2, city, state, zipcode;
        const { SLA, projectAddress2, SLC, SLS, SLZ } = cookieAccount || {};
        const { location, jobSiteAddr2, jobSiteCity, jobSiteState, jobSiteZip } = viewCartData || {};
        const {
            projectAddress1,
            projectAddress2: projectDetailsAddress2,
            selectedProjectCity,
            selectedProjectState,
            selectedProjectZip
        } = projectDetails || {};

        if (userType === USER_TYPE.CREDIT && !NewAddress) {
            if (isAddressFromCookie) {
                line_1 = SLA || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
                line_2 = projectAddress2 || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
                city = SLC || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
                state = SLS || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
                zipcode = SLZ || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            } else {
                line_1 =
                    localLocation?.address1 || projectAddress1 || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
                line_2 =
                    localLocation?.address2 ||
                    projectDetailsAddress2 ||
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
                city =
                    localLocation?.city || selectedProjectCity || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
                state =
                    localLocation?.state ||
                    selectedProjectState ||
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
                zipcode =
                    localLocation?.zip || selectedProjectZip || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            }
        } else {
            line_1 = localLocation?.location || location || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            line_2 =
                localLocation?.jobSiteAddr2 || jobSiteAddr2 || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            city = localLocation?.jobSiteCity || jobSiteCity || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            state =
                localLocation?.jobSiteState || jobSiteState || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            zipcode = localLocation?.jobSiteZip || jobSiteZip || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
        }

        return { line_1, line_2, city, state, zipcode };
    };

    const getListItemDetails = listName => {
        switch (pageType) {
            case VARIABLE_CONFIG.PAGE_TYPE.PLP:
                return {
                    list_id: VARIABLE_CONFIG.ECOMMERCE_PAGE.PLP,
                    list_name: VARIABLE_CONFIG.PRODUCT_CARD.PLP_LIST_NAME
                };

            case VARIABLE_CONFIG.PAGE_TYPE.SEARCH_PAGE:
                return {
                    list_id: VARIABLE_CONFIG.ECOMMERCE_PAGE.SRP,
                    list_name: VARIABLE_CONFIG.PRODUCT_CARD.SRP_LIST_NAME
                };

            case VARIABLE_CONFIG.ECOMMERCE_PAGE.DETAIL:
                if (listName === VARIABLE_CONFIG.ALTERNATE_INVENTORY.SIMILAR_EQUIPMENT) {
                    return {
                        list_id: VARIABLE_CONFIG.ALTERNATE_INVENTORY.SIMILAR,
                        list_name: VARIABLE_CONFIG.ALTERNATE_INVENTORY.SIMILAR_EQUIPMENT
                    };
                } else {
                    return {
                        list_id: VARIABLE_CONFIG.PRODUCT.LIST_ID,
                        list_name: VARIABLE_CONFIG.PRODUCT.LIST_NAME
                    };
                }

            case VARIABLE_CONFIG.PAGE_TYPE.EQUIPMENT_AND_TOOLS_PAGE:
                if (listName === VARIABLE_CONFIG.PRODUCT_CARD.RECOMMENDED_PRODUCTS_LIST_NAME) {
                    return {
                        list_id: VARIABLE_CONFIG.PRODUCT_CARD.RECOMMENDED_PRODUCTS_LIST_ID,
                        list_name: VARIABLE_CONFIG.PRODUCT_CARD.RECOMMENDED_PRODUCTS_LIST_NAME
                    };
                } else if (listName === VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME) {
                    return {
                        list_id: VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_ID,
                        list_name: VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME
                    };
                } else {
                    return {
                        list_id: VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_ID,
                        list_name: VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_NAME
                    };
                }

            case VARIABLE_CONFIG.ECOMMERCE_PAGE.CART:
                return {
                    list_id: VARIABLE_CONFIG.CART.LIST_ID,
                    list_name: VARIABLE_CONFIG.CART.LIST_NAME
                };

            default:
                return {};
        }
    };

    const getItemsForAnalytics = (itemsList = {}) => {
        let itemsCart = [];
        let assetsRates = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES)
            ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES))
            : {};
        let requiredCartItems = isCheckoutPage() ? cart?.availableCartItems : cart?.items;
        requiredCartItems?.forEach((item, index) => {
            const { product, prices, quantity } = item || {};
            const { daily, weekly, monthly } = assetsRates?.[product?.sku] || {};
            const items = {
                item_id: product?.sku,
                item_name: product?.name,
                categories: product?.category_path,
                price: prices?.price?.value,
                quantity: quantity,
                index: index + 1,
                daily: daily || prices?.price?.value || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                weekly: weekly || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                monthly: monthly || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
            };
            itemsCart.push({ ...items, ...itemsList });
        });
        return itemsCart;
    };

    const createInventoryItemObject = (data, index) => {
        const { catclass, producttitle, category } = data || {};
        const { daily, weekly, monthly } = homeAssetsRates?.[catclass] || {};
        const item = {
            index: index,
            item_id: catclass || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            item_name: producttitle || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            item_list_id: VARIABLE_CONFIG.ALTERNATE_INVENTORY.SIMILAR,
            item_list_name: VARIABLE_CONFIG.ALTERNATE_INVENTORY.SIMILAR_PRODUCTS,
            categories: category || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            price: isNaN(daily) ? VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY : daily,
            quantity: 1,
            daily: isNaN(daily) ? VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY : daily,
            weekly: isNaN(weekly) ? VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY : weekly,
            monthly: isNaN(monthly) ? VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY : monthly
        };
        return item;
    };

    const getEcommerceDataWithoutItems = (startDate, endDate) => {
        return {
            currency:
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 2
                    ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                    : VARIABLE_CONFIG.CURRENCY_CODE.USA,
            value:
                isValidString(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ORDER_ESTIMATES_FINAL_TOTAL)) ||
                (isEstimatesWithOwnedPc()
                    ? cart?.prices?.grand_total?.value
                    : cart?.estimatesResponse?.estimate?.totals?.rentalAmount) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            delivery_method: isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP))
                ? VARIABLE_CONFIG.EVENT_LABEL.INSTORE_PICKUP
                : VARIABLE_CONFIG.EVENT_LABEL.ROUNDTRIP_DELIVERY,
            delivery_fee: getDeliveryFee(),
            start_date:
                startDate ||
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            end_date:
                endDate ||
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
        };
    };

    const getEcommerceData = (startDate, endDate) => {
        return {
            ...getEcommerceDataWithoutItems(startDate, endDate),
            items: getItemsForAnalytics()
        };
    };

    const createListingItemObject = (products, isProduct, rates, dataset) => {
        let items = [];
        const assetsRates = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES)) || {};
        const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
        products?.forEach((product, index) => {
            const {
                additionalFields,
                raw,
                ec_pc_inventory,
                catclass,
                disableaddtocartoption,
                showonlinecatalog,
                ec_name,
                name,
                producttitle,
                ec_category,
                category_path,
                categoryname,
                parentCategory,
                subcategoryname,
                pc_availability,
                listName,
                inventory
            } = product || {};
            const sku = additionalFields?.ec_skus?.[0] || raw?.ec_skus?.[0] || catclass;
            const productInventory =
                additionalFields?.ec_pc_inventory || raw?.ec_pc_inventory || ec_pc_inventory || inventory;
            const disableAtcOption =
                additionalFields?.ec_disable_addtocartoption ||
                raw?.ec_disable_addtocartoption ||
                disableaddtocartoption;
            const showOnlineCatalog =
                additionalFields?.ec_show_onlinecatalog || raw?.ec_show_onlinecatalog || showonlinecatalog;
            const { list_id, list_name } = getListItemDetails(listName) || {};
            const assetsRatesSku = assetsRates?.[sku] || {};
            const pdpRates = rates[0]?.rates?.suggestedRates || {};

            const item = {
                item_id: sku || dataset?.catsku || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                item_name:
                    ec_name?.trim() ||
                    raw?.ec_name?.trim() ||
                    name?.trim() ||
                    producttitle?.trim() ||
                    dataset?.productname?.trim() ||
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                item_list_id: list_id,
                item_list_name: list_name,
                categories: getUniqueCategories(
                    ec_category ||
                        raw?.ec_category ||
                        category_path || [
                            categoryname || dataset?.categoryname,
                            parentCategory || dataset?.parentcategory,
                            subcategoryname || dataset?.supercategory
                        ]
                ),
                price: assetsRatesSku?.daily || pdpRates?.daily,
                quantity: 1,
                daily: assetsRatesSku?.daily || pdpRates?.daily,
                weekly: assetsRatesSku?.weekly || pdpRates?.weekly,
                monthly: assetsRatesSku?.monthly || pdpRates?.monthly,
                availability: getAvailabilityStatus(
                    sku,
                    productInventory,
                    disableAtcOption,
                    showOnlineCatalog,
                    pc_availability
                ),
                location_id:
                    overridePC?.pc ||
                    assetsRatesSku?.ownedPc ||
                    rates[0]?.location ||
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
            };

            if (isProduct) {
                item.index = index;
            } else {
                item.position = index + 1;
            }

            items.push(item);
        });
        return items;
    };

    const getFormattedConsumablesObj = consumablesObj => {
        let unDefinedString = VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
        let formattedObj = {};
        Object.keys(consumablesObj)?.forEach(itemkey => {
            let addons = [];
            if (Object.keys(consumablesObj[itemkey]?.addons).length > 0) {
                Object.keys(consumablesObj[itemkey]?.addons)?.forEach((elementKey, index) => {
                    const { name, price, qty } = consumablesObj[itemkey]?.addons[elementKey] || {};
                    const addOn = {
                        item_id: elementKey,
                        item_name: name,
                        categories: unDefinedString,
                        price: price,
                        quantity: qty,
                        index: index + 1,
                        daily: unDefinedString,
                        weekly: unDefinedString,
                        monthly: unDefinedString
                    };
                    addons.push(addOn);
                });
            }
            let rentals = [];
            if (Object.keys(consumablesObj[itemkey]?.rentals).length > 0) {
                Object.keys(consumablesObj[itemkey]?.rentals)?.forEach((elementKey, index) => {
                    const { name, price, qty } = consumablesObj[itemkey]?.rentals[elementKey] || {};
                    const rental = {
                        item_id: elementKey,
                        item_name: name,
                        categories: unDefinedString,
                        price: price,
                        quantity: qty,
                        index: index + 1,
                        daily: unDefinedString,
                        weekly: unDefinedString,
                        monthly: unDefinedString
                    };
                    rentals.push(rental);
                });
            }
            formattedObj = {
                ...formattedObj,
                [itemkey]: {
                    addons: addons,
                    rentals: rentals
                }
            };
        });
        return formattedObj;
    };

    const formatProductDetailsAnalytics = (productDetails, dailyProductRate) => {
        let assets = [];
        const {
            catclass,
            producttitle,
            category_path,
            productcategoryname,
            subcategoryname,
            position,
            productimageurl,
            categoryname,
            parentCategory,
            rankedOrder,
            listName,
            index
        } = productDetails || {};
        let productObj = {
            id: catclass,
            name: producttitle,
            categories:
                category_path ||
                getUniqueCategories([productcategoryname || categoryname, parentCategory, subcategoryname]) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
            category: productcategoryname || categoryname || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
            subcategory: subcategoryname || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
            dimension38: VARIABLE_CONFIG.EMPTY_STRING.PAGE_UNSPECIFIED,
            dimension31: dailyProductRate,
            dimension32: dailyProductRate * 7,
            dimension33: dailyProductRate * 31,
            sku: catclass,
            dimension41: -1,
            list: listName || pageType,
            position: parseInt(position || rankedOrder || index + 1) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
            quantity: 1,
            imageUrl: productimageurl || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED
        };
        const productPrice = parseFloat(dailyProductRate).toFixed(2);
        if (!isNaN(productPrice)) {
            productObj.price = productPrice || '0';
        }
        assets.push(productObj);
        return assets;
    };

    return [
        {
            bussinessSize,
            createInventoryItemObject,
            createListingItemObject,
            formatProductDetailsAnalytics,
            getAddressPayload,
            getAvailabilityStatus,
            getDeliveryFee,
            getEcommerceData,
            getEcommerceDataWithoutItems,
            getFormattedConsumablesObj,
            getItemAvailabilityTileStatus,
            getItemsForAnalytics,
            getItemStatusDetails,
            getListItemDetails,
            getStartingInStatus
        }
    ];
};

export default useAnalyticsHelper;
